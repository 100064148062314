
<template>
    <b-overlay :show="showLoader">
        <b-card class="my-1 d-flex align-center" title="Uporabniki">
            <b-row>
                <b-col class="text-right">
                    <b-button variant="primary" @click="$refs.modal.open()">
                        <feather-icon icon="PlusSquareIcon"/>
                        Dodaj uporabnika
                    </b-button>
                </b-col>
            </b-row>
            <basic-table :columns="columns" :data="clientUsers" v-slot="props">
                <span v-if="props.column.displayType === 2" class="d-flex justify-content-center">
                    <b-button variant="warning" class="mr-1" @click="$refs.modal.open(clientUsers[props.row.originalIndex])"><feather-icon icon="EditIcon"/></b-button>
                    <b-button variant="danger" class="" @click="remove(clientUsers[props.row.originalIndex].id)"><feather-icon icon="Trash2Icon"/></b-button>
                </span>
                <span v-else-if="props.column.displayType === 1">
                    <span v-if="props.row.client_ids && clients && clients.length > 0">
                        {{
                            clients.filter(ele => props.row.client_ids.includes(ele.id) ).map(function(item) {
                                return item.name;
                            }).join(", ")
                        }}
                    </span>
                    <span v-else>/</span>
                </span>
                <span v-else-if="props.column.displayType === 3" class="d-flex justify-content-center">
                    <div v-for="item of userTypes" :key="item.value">
                        <b-badge v-if="props.row.user_type === item.value" :variant="item.variant">
                            {{item.text}}
                        </b-badge>
                    </div>
                </span>
                <span v-else-if="props.column.displayType === 4" class="d-flex justify-content-center">
                    {{props.row.client_ids ? props.row.client_ids.length : '/'}}
                </span>
                <span v-else>
                    {{ props.formattedRow[props.column.field] }}
                </span>
            </basic-table>
        </b-card>
        <AddClientUser ref="modal" :clients="clients" v-on:itemAdded="loadData"/>
    </b-overlay>
</template>

<script>
    import BasicTable from '@/views/components/BasicTable'
    import AddClientUser from '@/views/ClientUser/AddClientUser.vue'
    import {BButton, BCard, BOverlay, BCol, BRow, BBadge} from 'bootstrap-vue'
    import {UserTypes} from '@/libs/enums/UserTypes'

    export default {
        components: {BasicTable, AddClientUser, BCard, BOverlay, BButton, BCol, BRow, BBadge},
        data() {
            return {
                clients: [],
                clientUsers: [],
                userTypes: [
                    {value: UserTypes.User, text: 'User', variant: 'primary'},
                    {value: UserTypes.Admin, text: 'Admin', variant: 'secondary'},
                    {value: UserTypes.SuperAdmin, text: 'Super Admin', variant: 'warning'}
                ],
                columns: [
                    {
                        label: 'Ime',
                        field: 'name',
                        displayType: 0,
                        filterOptions: {
                            enabled: true
                        }
                    },
                    {
                        label: 'Email',
                        field: 'email',
                        displayType: 0,
                        filterOptions: {
                            enabled: true
                        }
                    },
                    {
                        label: 'Tip',
                        field: 'user_type',
                        displayType: 3,
                        filterOptions: {
                            enabled: true,
                            filterDropdownItems: ['User', 'Admin', 'Super Admin'],
                            filterFn: this.typesFilter
                        },
                        sortable: false
                    },
                    {
                        label: 'Število strank',
                        field: 'client_ids',
                        displayType: 4,
                        filterOptions: {
                            enabled: false
                        },
                        sortFn: this.sortClients

                    },
                    {
                        label: 'Možnosti',
                        field: 'options',
                        displayType: 2,
                        filterOptions: {
                            enabled: false
                        },
                        sortable: false
                    }
                ],
                showLoader: false
            }
        },
        methods: {
            sortClients(data1, data2) {
                if (!data1) {
                    data1 = []
                }

                if (!data2) {
                    data2 = []
                }
                return data1.length - data2.length
            },
            typesFilter(data, filterString) {
                if (filterString === 'User' && data === UserTypes.User) {
                    return true
                } else if (filterString === 'Admin' && data === UserTypes.Admin) {
                    return true
                } else if (filterString === 'Super Admin' && data === UserTypes.SuperAdmin) {
                    return true
                }

                return false
            },
            async loadData() {
                try {
                    this.showLoader = true
                    const responseUsers = await this.$http.get('/api/admin/v1/users/')
                    this.clientUsers = responseUsers.data ?? []

                    const response = await this.$http.get('/api/admin/v1/client/')
                    this.clients = response.data ?? []

                } catch {
                    this.$printError('Prišlo je do napake')
                } finally {
                    this.showLoader = false
                }

            },
            async remove(id) {
                try {
                    const confirmDelete = await this.$confirmDialog('Ali res želite izbrisati vrstico?')
                    if (!confirmDelete) return
                    await this.$http.delete(`api/admin/v1/users/${id}`)
                    this.$printSuccess('Brisanje je bilo uspešno!')
                    this.loadData()
                } catch (error) {
                    this.$printError((error.response) ? error.response.data : error)

                }
            }
        },  
        mounted() {
            this.loadData()
        }
    }
</script>

<style scoped>

</style>