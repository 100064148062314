var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-modal',{attrs:{"title":"Uporabnik","size":"lg","no-close-on-backdrop":""},scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('validation-observer',{ref:"validation"},[_c('b-form-group',{attrs:{"label":"Ime"}},[_c('validation-provider',{attrs:{"name":"Ime","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{model:{value:(_vm.object.name),callback:function ($$v) {_vm.$set(_vm.object, "name", $$v)},expression:"object.name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label":"Email"}},[_c('validation-provider',{attrs:{"name":"Email","rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"autocomplete":"new-password","disabled":_vm.disabledEmail},model:{value:(_vm.object.email),callback:function ($$v) {_vm.$set(_vm.object, "email", $$v)},expression:"object.email"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),(_vm.editing)?_c('b-form-group',{attrs:{"label":"Geslo"}},[_c('validation-provider',{attrs:{"name":"Geslo"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"autocomplete":"new-password","placeholder":"Prazno polje pomeni nespremenjeno geslo","type":"password"},model:{value:(_vm.object.password),callback:function ($$v) {_vm.$set(_vm.object, "password", $$v)},expression:"object.password"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,4090402492)})],1):_c('b-form-group',{attrs:{"label":"Geslo"}},[_c('validation-provider',{attrs:{"name":"Geslo","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"autocomplete":"new-password","type":"password"},model:{value:(_vm.object.password),callback:function ($$v) {_vm.$set(_vm.object, "password", $$v)},expression:"object.password"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label":"Administrator"}},[_c('v-select',{attrs:{"options":_vm.UserTypes,"label":"name","reduce":function (item) { return item.value; },"clearable":false,"appendToBody":""},model:{value:(_vm.object.user_type),callback:function ($$v) {_vm.$set(_vm.object, "user_type", $$v)},expression:"object.user_type"}})],1),_c('b-form-group',{attrs:{"label":"Jeziki"}},[_c('validation-provider',{attrs:{"name":"Jeziki","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"options":_vm.localeObjects(),"reduce":function (item) { return item.id; },"appendToBody":"","clearable":false},scopedSlots:_vm._u([{key:"selected-option",fn:function(ref){
var label = ref.label;
var img = ref.img;
return [_c('div',[_c('b-img',{attrs:{"height":"14px","width":"22px","src":img}}),_vm._v(" "+_vm._s(label)+" ")],1)]}},{key:"option",fn:function(option){return [_c('b-img',{attrs:{"height":"14px","width":"22px","src":option.img}}),_vm._v(" "+_vm._s(option.label)+" ")]}}],null,true),model:{value:(_vm.object.language),callback:function ($$v) {_vm.$set(_vm.object, "language", $$v)},expression:"object.language"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),(_vm.editing)?_c('div',[_c('div',[_vm._v(" Uporabnik dodan na strankah ")]),_c('basic-table',{attrs:{"columns":_vm.columns,"pageLength":8,"lengths":['8'],"data":_vm.client_ids},scopedSlots:_vm._u([{key:"default",fn:function(props){return [(props.column.field === 'id')?_c('span',[_vm._v(" "+_vm._s(_vm.getClientName(props.row.id))+" ")]):(props.column.field === 'actions')?_c('span',{staticClass:"d-flex justify-content-center"},[_c('b-button',{attrs:{"variant":"danger"},on:{"click":function($event){return _vm.remove(props.row.id)}}},[_c('feather-icon',{attrs:{"icon":"Trash2Icon"}})],1)],1):_vm._e()]}}],null,false,2189447588)})],1):_vm._e()]},proxy:true},{key:"modal-footer",fn:function(){return [_c('b-button',{attrs:{"variant":"primary"},on:{"click":_vm.validate}},[(_vm.editing)?_c('span',[_c('feather-icon',{staticClass:"mr-50",attrs:{"icon":"EditIcon"}}),_c('span',{staticClass:"align-middle"},[_vm._v("Uredi")])],1):_c('span',[_c('feather-icon',{staticClass:"mr-50",attrs:{"icon":"PlusIcon"}}),_c('span',{staticClass:"align-middle"},[_vm._v("Dodaj")])],1)])]},proxy:true}]),model:{value:(_vm.modalActive),callback:function ($$v) {_vm.modalActive=$$v},expression:"modalActive"}})}
var staticRenderFns = []

export { render, staticRenderFns }